<template>
  <div>
    <creatsuccess :msg="showtype" @recovery="recovery" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="form" label-width="160px" style="margin-bottom:80px">
        <el-card>
          <div slot="header" class="font-16">基本信息</div>
          <el-form-item label="活动时间：" prop="StartTime">
            <div class="flex flex-align-center">
              <el-date-picker :disabled="form.State>0"
                v-model="form.StartTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :picker-options="options"
                type="datetime"
                placeholder="开始时间">
              </el-date-picker>
              <span style="margin:0px 10px">~</span>
              <el-date-picker :disabled="form.State==2"
                v-model="form.EndTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="结束时间">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="活动说明：">
            <el-input v-model="form.ActivityDescription" type="textarea" :rows="6" style="width:500px" placeholder="活动说明最多500个字"
              :disabled="form.State==2" maxlength="500"></el-input>
          </el-form-item>
        </el-card>
        <el-card class="margin-top-10">
          <div slot="header" class="font-16">活动商品</div>
          <el-form-item label="活动商品：" prop="shoplist">
            <el-button type="text" @click="openshopDialog" v-if="form.State==0">选择商品</el-button>
            <el-table :data="form.shoplist" style="width:800px">
              <el-table-column prop="" label="商品" min-width="150">
                <template slot-scope="scope">
                  <div class="flex flex-align-center">
                    <img :src="scope.row.ImgUrlComplete" style="width:60px;height:60px;margin-right:10px;border-radius:2px" alt="">
                    <div style="flex:1;width:0">
                      <div class="ellipsis">{{scope.row.ProductName}}</div>
                      <div class="font-12 ellipsis" style="color:#999999FF">{{scope.row.ProductNo}}</div>
                      <div class="remark">
                        <span v-if="!scope.row.IsOpen">商品已下架</span>
                        <span v-else-if="scope.row.Stock==0">商品已售罄</span>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="原价(元)">
                <template slot-scope="scope">
                  <div>
                    {{scope.row.ProductPrice}} <span v-if="scope.row.ProductPrice<scope.row.ProductMaxPrice">~{{scope.row.ProductMaxPrice}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="Stock" label="库存"></el-table-column>
              <el-table-column prop="" label="活动价(元)" width="150">
                <template slot-scope="scope">
                  <el-form-item label-width="0px" :prop="'shoplist.'+scope.$index+'.ActivityPrice'" :rules="rules.ActivityPrice" style="margin:22px 0px">
                    <el-input style="width:130px" v-model="scope.row.ActivityPrice"  :disabled="form.State==2"
                      oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-card>
        <el-card class="margin-top-10">
          <div slot="header" class="font-16">活动规则</div>
          <el-form-item label="助力人数：" prop="HelpNumber">
            <div class="flex flex-align-center">
              <el-input style="width:200px" v-model="form.HelpNumber" :disabled="form.State>0"
                onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
              <span style="margin-left:10px">人</span>
            </div>
            <div class="font-12" style="color:#999999FF;line-height:2">客户发起助力后，需要邀请助力的人数；人数最少1人；不包含发起人</div>
          </el-form-item>
          <el-form-item label="助力时间：" prop="HelpEffectiveTime">
            <div class="flex flex-align-center">
              <el-input style="width:200px" v-model="form.HelpEffectiveTime" :disabled="form.State>0"
                onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
              <span style="margin-left:10px">小时</span>
            </div>
            <div class="font-12" style="color:#999999FF;line-height:2">客户发起助力后，需要在该时间内拉满助力人数；该时间结束或活动时间结束，未拉满人数，助力失败</div>
          </el-form-item>
          <el-form-item label="发起助力次数限制：" required>
            <el-radio-group v-model="form.IsLimitInitiateCount" class="flex flex-align-center" :disabled="form.State==2">
              <div>
                <el-radio :label="false">不限制</el-radio>
              </div>
              <div class="flex flex-align-center margin-left-40 font-14">
                <el-radio :label="true" style="margin-right:10px">本场活动，每人限制发起</el-radio>
                <el-form-item label-width="0px" prop="LimitInitiateCount" style="width:300px">
                  <el-input style="width:100px" v-model="form.LimitInitiateCount"  :disabled="form.State==2||!form.IsLimitInitiateCount"
                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  <span style="margin-left:10px">次</span>
                </el-form-item>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="帮忙助力次数限制：" required>
            <el-radio-group v-model="form.IsLimitHelpCount" class="flex flex-align-center" :disabled="form.State==2">
              <div>
                <el-radio :label="false">不限制</el-radio>
              </div>
              <div class="flex flex-align-center margin-left-40 font-14">
                <el-radio :label="true" style="margin-right:10px">本场活动，每人最多可帮助</el-radio>
                <el-form-item label-width="0px" prop="LimitHelpCount" style="width:300px">
                  <el-input style="width:100px" v-model="form.LimitHelpCount" :disabled="form.State==2||!form.IsLimitHelpCount"
                    onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                    onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                  <span style="margin-left:10px">次</span>
                </el-form-item>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="互相助力限制：">
            <div class="font-14" style="color:#999999FF">本场活动中，A和B存在助力关系，A是发起人，B是A的帮助人</div>
            <div class="flex flex-align-center">
              <el-checkbox v-model="form.IsACanHelpB" :disabled="form.State==2">A可帮助B发起的助力，最多帮助</el-checkbox>
              <el-form-item label-width="0px" prop="ACanHelpBCount" style="width:300px">
                <el-input style="width:100px" v-model="form.ACanHelpBCount" class="margin-left-10" :disabled="!form.IsACanHelpB||form.State==2"
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                <span style="margin-left:10px">次</span>
              </el-form-item>
            </div>
            <div class="flex flex-align-center margin-top-20">
              <el-checkbox v-model="form.IsBCanHelpA" :disabled="form.State==2">B可继续帮助A发起的助力，最多帮助</el-checkbox>
              <el-form-item label-width="0px" prop="BCanHelpACount" style="width:300px">
                <el-input style="width:100px" v-model="form.BCanHelpACount" class="margin-left-10" :disabled="!form.IsBCanHelpA||form.State==2"
                  onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                  onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"></el-input>
                <span style="margin-left:10px">次</span>
              </el-form-item>
            </div>
          </el-form-item>
        </el-card>
        <el-card class="margin-top-10">
          <div slot="header" class="font-16">活动权益配置</div>
          <el-form-item label="邀请奖励权益：">
            <div class="margin-bottom-20">
              <el-radio v-model="form.RecommendRewardType" :label="1" :disabled="form.State>0" @click.native="cancelraido" class="radiocheck">退回支付款项</el-radio>
              <div class="radio-tips">拉满规定人数完成助力后，给发起人退回支付款项</div>
            </div>
            <div class="margin-bottom-20">
              <el-radio v-model="form.RecommendRewardType" :label="2" :disabled="form.State>0" @click.native="cancelraido"
                 class="radiocheck" @change="form.CashRewardType?'':form.CashRewardType=1">现金奖励</el-radio>
              <div class="radio-tips">成功邀请他人帮忙助力，发起人可获得现金奖励；获得奖励7天后可申请提现，在可提现前，帮助人退款订单，扣减相应奖励</div>
              <div style="margin-left:25px" v-if="form.RecommendRewardType==2">
                <div class="flex flex-align-center margin-top-20">
                  <el-radio class="margin-right-10" v-model="form.CashRewardType" :disabled="form.State>0||form.RecommendRewardType!=2" 
                   :label="1">完成助力发放 拉满规定人数完成助力后发放</el-radio>
                  <el-form-item label-width="0px" prop="CashRewardMoney" style="width:300px">
                    <el-input style="width:100px" v-model="form.CashRewardMoney"
                      :disabled="form.State>1||form.RecommendRewardType!=2||form.CashRewardType!=1"
                      oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                    <span style="margin-left:10px">元</span>
                  </el-form-item>
                </div>
                <div class="margin-top-20">
                  <el-radio class="margin-right-10" v-model="form.CashRewardType" :disabled="form.State>0||form.RecommendRewardType!=2"
                   :label="2">即时发放 
                    <span style="color:#999999FF">帮助人支付成功，发起人即可获得现金奖励</span> 
                  </el-radio>
                  <div style="margin-left:25px" v-if="form.CashRewardType==2">
                    <div class="flex flex-align-center" v-if="form.ProductId">
                      <img :src="form.ImgUrlComplete" style="width:60px;height:60px;margin-right:10px;border-radius:2px" alt="">
                      <div style="flex:1;width:0">
                        <div class="ellipsis">{{form.ProductName}}</div>
                        <div class="font-12 ellipsis" style="color:#999999FF">{{form.ProductNo}}</div>
                      </div>
                    </div>
                    <el-table :data="form.CashRewardImmediatelyList" class="margin-top-20" style="width:800px">
                      <el-table-column prop="" label="规格" min-width="150">
                        <template slot-scope="scope">
                          <div class="ellipsis">{{scope.row.Spec}}</div>
                          <div class="font-12 ellipsis" style="color:#999999FF">{{scope.row.Barcode}}</div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="Price" label="原价(元)"></el-table-column>
                      <el-table-column prop="" label="邀请奖励(元/件)">
                        <template slot-scope="scope">
                          <el-form-item label-width="0px" :prop="'CashRewardImmediatelyList.'+scope.$index+'.RewardMoney'" :rules="rules.RewardMoney" style="margin:22px 0px">
                            <el-input style="width:130px" v-model="scope.row.RewardMoney" placeholder="0"
                              :disabled="form.State>1||form.RecommendRewardType!=2||form.CashRewardType!=2"
                              oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
            <div class="margin-bottom-20">
              <div class="flex flex-align-center">
                <el-radio v-model="form.RecommendRewardType" :label="3" :disabled="form.State>0" @click.native="cancelraido" class="radiocheck">赠送商品</el-radio>
                <el-button type="text" style="margin:0px;padding:0px" @click="selectGiftShow=true"
                 v-if="form.RecommendRewardType==3&&form.State!=2">选择赠品</el-button>
              </div>
              <div class="radio-tips" style="margin-top:5px">1.拉满规定人数完成助力后，可获得赠送商品；本场活动，完成多次助力，赠送多次</div>
              <div class="radio-tips" style="margin-top:5px">2.若商品剩余库存少于赠送数量，将不再赠送对应商品。请关注商品库存并及时补充</div>
              <div class="margin-top-20" style="margin-left:25px" v-if="form.RecommendRewardType==3">
                <el-form-item label-width="0px" prop="ProductGiftList">
                  <el-table :data="form.ProductGiftList" class="gifttable">
                    <el-table-column label="商品" width="300px">
                      <template slot-scope="scope">
                        <div class="dialog-name-content">
                          <img :src="imgUrl+scope.row.ImgUrl" />
                          <div class="right">
                            <div class="name">{{scope.row.ProductName}}</div>
                            <div class="remark">
                              <span v-if="!scope.row.IsOpen">商品已下架</span>
                              <span v-else-if="scope.row.Stock==0">商品已售罄</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="Spce" label="规格">
                      <template slot-scope="scope">
                        <div>{{scope.row.Spce||'默认规格'}}</div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="Price" label="价格">
                    </el-table-column>
                    <el-table-column prop="Stock" label="库存"></el-table-column>
                    <el-table-column label="赠送数量/人">
                      <template slot-scope="scope">
                        <el-form-item label-width="0" :prop="'ProductGiftList.'+scope.$index+'.GiftCount'" :rules="rules.GiftCount"
                         style="margin:30px 0px" :key="scope.row.ProductSpecId">
                          <el-input style="width:100px;" v-model="scope.row.GiftCount" :disabled="form.RecommendRewardType!=3||form.State>1"
                            @blur="assignment($event,scope.row)"
                            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
                          </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button style="color:#F56C6C;" @click="deleteSortGift(scope.row)" type="text" :disabled="form.State>1">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="margin-top-10" style="text-align:right" v-if="total">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="currentPage"
                      :page-sizes="[5, 10, 15, 20]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="total">
                    </el-pagination>
                  </div>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="分享赚权益：">
            <el-checkbox v-model="form.IsOpenShareEarnEquity" :disabled="form.State==2">开启</el-checkbox>
            <div class="radio-tips" style="margin-top:5px">1.开启分享赚权益，本场活动，客户完成1次助力（拉满规定人数）后，可享有该权益</div>
            <div class="radio-tips" style="margin-top:5px">2.客户分享商品活动链接给好友，好友支付成功后即发送奖励</div>
            <div class="radio-tips" style="margin-top:5px">3.奖励确认收货后可提现；确认收货前退款，按退回件数扣减相应奖励</div>
            <div style="margin-left:25px" v-if="form.IsOpenShareEarnEquity">
              <div class="flex flex-align-center" v-if="form.ProductId">
                <img :src="form.ImgUrlComplete" style="width:60px;height:60px;margin-right:10px;border-radius:2px" alt="">
                <div style="flex:1;width:0">
                  <div class="ellipsis">{{form.ProductName}}</div>
                  <div class="font-12 ellipsis" style="color:#999999FF">{{form.ProductNo}}</div>
                </div>
              </div>
              <el-table :data="form.ShareEarnEquityList" class="margin-top-20" style="width:800px">
                <el-table-column prop="" label="规格" min-width="150">
                  <template slot-scope="scope">
                    <div class="ellipsis">{{scope.row.Spec}}</div>
                    <div class="font-12 ellipsis" style="color:#999999FF">{{scope.row.Barcode}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="Price" label="原价(元)"></el-table-column>
                <el-table-column prop="" label="分享赚奖励(元/件)">
                  <template slot-scope="scope">
                    <el-form-item label-width="0px" :prop="'ShareEarnEquityList.'+scope.$index+'.ShareEarnMoney'" :rules="rules.ShareEarnMoney" style="margin:22px 0px">
                      <el-input style="width:130px" v-model="scope.row.ShareEarnMoney" placeholder="0" :disabled="form.State==2||!form.IsOpenShareEarnEquity"
                        oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-card>
      </el-form>
      <el-card class="bottomCard" style="padding-left:5%">
        <el-button @click="goback">取消</el-button>
        <el-button type="primary" @click="save('form')" v-if="form.State!=2">保存</el-button>
      </el-card>
    </creatsuccess>

    <el-dialog title="选择商品" :visible.sync="selectProShow" v-if="selectProShow" width="1100px" class="dialog data-dialog">
      <select-produce :proCheckedId='form.ProductId||0' :couponData="{
        StartTime:form.StartTime,
        EndTime:form.EndTime,
        id:form.ActivityFullId
      }" @getSelection='getSelectPros' :Num='2'></select-produce>
    </el-dialog>

    
		<!-- 选择赠品 -->
		<el-dialog title="选择赠品" :visible.sync="selectGiftShow" width="1300px" class="dialog">
			<select-gift api="productSpecPopList" :couponData="{}" :isShowSpecValue='true'
				:params="['KeyWords', 'ProductType', 'ProductGroupId', 'ProductBrandId', 'OnlyChoosable','ActivityCouponId','AcctivityRangeType','IsGift']"
				:selectedData="selectedData" @getSelectList="getSelectGiftList"
				:visible.sync="selectGiftShow" v-if="selectGiftShow"></select-gift>
		</el-dialog>
  </div>
</template>

<script>
import selectProduce from '../association/turntableActivities'
import selectGift from '../discountActivity/SelectMulGift'
import config from '@/config/index'
import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
import {
  activityHelpProactivityHelpProSave,
  activityHelpProactivityHelpProInfo,
} from "@/api/sv3.0.0"
export default {
  components:{
    selectProduce,
    selectGift,
    creatsuccess
  },
  data () {
    var checkStartTime = (rule, value, callback) => {
      if(!this.form.StartTime||!this.form.EndTime){
        callback(new Error('请完善活动时间'))
      }else if(new Date(this.form.StartTime).getTime()>=new Date(this.form.EndTime).getTime()){
        callback(new Error('开始时间不得晚于结束时间'))
      }
      callback()
    }
    var checkprice = (rule, value, callback) => {
      if(!value){
        callback(new Error('请输入活动价'))
      }else if(value<0.01){
        callback(new Error('活动价不可低于0.01元'))
      }else if(value>this.form.ProductMaxPrice){
        callback(new Error('活动价需低于原价'))
      }
      callback()
    }
    var checkHelpNumber = (rule, value, callback) => {
      if(!value||value<1||value>1000){
        callback(new Error('人数请输入在1~1000人之间，整数'))
      }
      callback()
    }
    var checkHelpEffectiveTime = (rule, value, callback) => {
      if(!value||value<1||value>720||!this.one.test(value)){
        callback(new Error('时间请输入在1~720小时之间'))
      }
      callback()
    }
    var checkLimitInitiateCount = (rule, value, callback) => {
      if(this.form.IsLimitInitiateCount){
        if(!value||value<1||value>100){
          callback(new Error('次数请输入在1~100次之间，整数'))
        }
      }
      callback()
    }
    var checkLimitHelpCount = (rule, value, callback) => {
      if(this.form.IsLimitHelpCount){
        if(!value||value<1||value>100){
          callback(new Error('次数请输入在1~100次之间，整数'))
        }
      }
      callback()
    }
    var checkACanHelpBCount = (rule, value, callback) => {
      if(this.form.IsACanHelpB){
        if(!value||value<1||value>100){
          callback(new Error('次数请输入在1~100次之间，整数'))
        }
      }
      callback()
    }
    var checkBCanHelpACount = (rule, value, callback) => {
      if(this.form.IsBCanHelpA){
        if(!value||value<1||value>100){
          callback(new Error('次数请输入在1~100次之间，整数'))
        }
      }
      callback()
    }
    var checkCashRewardMoney = (rule, value, callback) => {
      if(this.form.RecommendRewardType==2&&this.form.CashRewardType==1){
        console.log(value)
        if(value<=0||value>99999||!this.two.test(value)){
          callback(new Error('请设置正确奖励'))
        }
      }
      callback()
    }
    var checkRewardMoney = (rule, value, callback) => {
      if(this.form.RecommendRewardType==2&&this.form.CashRewardType==2){
        if(value<0||value>99999||!this.two.test(Number(value))){
          callback(new Error('请设置正确奖励'))
        }
      }
      callback()
    }
    var checkProductGiftList = (rule, value, callback) => {
      if(this.form.RecommendRewardType==3){
        if(!value||!value.length){
          callback(new Error('请选择赠送的赠品'))
        }
      }
      callback()
    }
    var checkGiftCount = (rule, value, callback) => {
      if(this.form.RecommendRewardType==3){
        let rules = rule.fullField.split('.')
        let stock = this.form[rules[0]][rules[1]].Stock
        // console.log(value)
        if(!value||value<=0){
          callback(new Error('赠品赠送数量需大于0，整数'))
        }else if(value > stock){
          callback(new Error('赠品赠送数量超出限制，请重新设置'))
        }
      }
      callback()
    }
    var checkShareEarnMoney = (rule, value, callback) => {
      if(this.form.IsOpenShareEarnEquity){
        if(value<0||value>99999||!this.two.test(Number(value))){
          callback(new Error('请设置正确奖励'))
        }
      }
      callback()
    }
    return {
      loading:false,
      form:{
        ActivityFullId:0,
        State:0,
        StartTime:'',
        EndTime:'',
        ActivityDescriptio:'',

        shoplist:[],
        ProductId:'',
        ProductName:'',
        ProductNo:'',
        ProductPrice:'',
        ProductMaxPrice:'',
        Stock:'',
        ActivityPrice:'',
        ImgUrlComplete:'',

        HelpNumber:'',
        HelpEffectiveTime:'',
        IsLimitInitiateCount:false,
        LimitInitiateCount:'',
        IsLimitHelpCount:false,
        LimitHelpCount:'',
        IsACanHelpB:false,
        ACanHelpBCount:'',
        IsBCanHelpA:false,
        BCanHelpACount:'',
        RecommendRewardType:0,
        CashRewardType:1,
        CashRewardMoney:'',
        CashRewardImmediatelyList:[],
        ProductGiftList:[],
        IsOpenShareEarnEquity:false,
        ShareEarnEquityList:[],
        ActivityHelpProProductSpecList:[],
      },
      rules:{
        StartTime:[{
          required:true,
          validator: checkStartTime, 
          trigger: 'blur'
        }],
        shoplist:[{
          required: true, 
          message: '请选择活动商品', 
          trigger: 'blur'
        }],
        ActivityPrice:[{
          required:true,
          validator: checkprice, 
          trigger: 'blur'
        }],
        HelpNumber:[{
          required:true,
          validator: checkHelpNumber, 
          trigger: 'blur'
        }],
        HelpEffectiveTime:[{
          required:true,
          validator: checkHelpEffectiveTime, 
          trigger: 'blur'
        }],
        LimitInitiateCount:[{
          required:true,
          validator: checkLimitInitiateCount, 
          trigger: 'blur'
        }],
        LimitHelpCount:[{
          required:true,
          validator: checkLimitHelpCount, 
          trigger: 'blur'
        }],
        ACanHelpBCount:[{
          required:true,
          validator: checkACanHelpBCount, 
          trigger: 'blur'
        }],
        BCanHelpACount:[{
          required:true,
          validator: checkBCanHelpACount, 
          trigger: 'blur'
        }],
        CashRewardMoney:[{
          required:true,
          validator: checkCashRewardMoney, 
          trigger: 'blur'
        }],
        RewardMoney:[{
          required:true,
          validator: checkRewardMoney, 
          trigger: 'blur'
        }],
        ProductGiftList:[{
          required:true,
          validator: checkProductGiftList, 
          trigger: 'blur'
        }],
        GiftCount:[{
          required:true,
          validator: checkGiftCount, 
          trigger: 'blur'
        }],
        ShareEarnMoney:[{
          required:true,
          validator: checkShareEarnMoney, 
          trigger: 'blur'
        }],
      },
      options:{
        disabledDate:(e)=>{
          return new Date(e).getTime() <= new Date().getTime() - 1000*60*60*24
        }
      },
      two:/^\d+(\.\d{1,2})?$/,
      one:/^\d+(\.\d{1,1})?$/,
      selectProShow:false,
      selectGiftShow:false,
      selectedData:[],
      currentPage:1,
      pagesize:5,
      total:0,
			imgUrl: config.IMG_BASE,
      showtype: {
        issuccess: false,
        id: 0,
        type: 0,
        suctype: 10,
      },
    }
  },
  mounted () {
    if(this.$route.query.Id>0){
      this.getinfo()
    }
  },
  methods: {
    cancelraido(e){
      if(e.target.tagName=='INPUT'){
        this.form.RecommendRewardType = ''
      }
    },
    goback(){
      this.$router.push({path:'/market/helpPro'})
    },
    assignment(e,row){
      // console.log(row)
      this.form.ProductGiftList = this.form.ProductGiftList.map(v=>{
        v.GiftCount = v.GiftCount==0?'':v.GiftCount
        return v
      })
      this.selectedData = this.selectedData.map(v=>{
        if(v.ProductSpecId==row.ProductSpecId){
          v.GiftCount = row.GiftCount
        }
        return v
      })
    },
    async getinfo(){
      this.loading = true
      try{
        let res = await activityHelpProactivityHelpProInfo({
          ActivityFullId:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          if(this.$route.query.copy=='true'){
            form.ActivityFullId = 0
            form.State = 0
            form.StartTime = ''
            form.EndTime = ''
          }
          form.shoplist = [{
            ActivityPrice:form.ActivityPrice,
            ImgUrlComplete:form.ImgUrlComplete,
            ProductName:form.ProductName,
            ProductNo:form.ProductNo,
            Stock:form.Stock,
            ProductPrice:form.ProductPrice,
            ProductMaxPrice:form.ProductMaxPrice,
            ProductId:form.ProductId,
            IsOpen:form.ProductIsOpen
          }]
          this.selectedData = form.ProductGiftList
          this.form = JSON.parse(JSON.stringify(form))
          this.getgiftlist()
          this.creatOtherlist('CashRewardImmediatelyList')
          this.creatOtherlist('ShareEarnEquityList')
        }
      }finally{
        this.loading = false
      }
    },
    recovery(){
      this.$router.push({path:this.$route.path,query:{
        Id:0
      }})
      this.showtype.issuccess = false
      this.form = {
        ActivityFullId:0,
        State:0,
        StartTime:'',
        EndTime:'',
        ActivityDescriptio:'',
        shoplist:[],
        ProductId:'',
        ProductName:'',
        ProductNo:'',
        ProductPrice:'',
        ProductMaxPrice:'',
        Stock:'',
        ActivityPrice:'',
        ImgUrlComplete:'',
        HelpNumber:'',
        HelpEffectiveTime:'',
        IsLimitInitiateCount:false,
        LimitInitiateCount:'',
        IsLimitHelpCount:false,
        LimitHelpCount:'',
        IsACanHelpB:false,
        ACanHelpBCount:'',
        IsBCanHelpA:false,
        BCanHelpACount:'',
        RecommendRewardType:0,
        CashRewardType:0,
        CashRewardMoney:'',
        CashRewardImmediatelyList:[],
        ProductGiftList:[],
        IsOpenShareEarnEquity:false,
        ShareEarnEquityList:[],
        ActivityHelpProProductSpecList:[],
      }
      this.selectedData = []
    },  
    deleteSortGift(row){
      this.selectedData = this.selectedData.filter(v=>{
        return v.ProductSpecId!=row.ProductSpecId
      })
      this.getgiftlist()
    },
    getSelectGiftList(list){
      let old = JSON.parse(JSON.stringify(this.selectedData))
      this.selectedData = list.map(v=>{
        let msg = old.find(x=>x.ProductSpecId==v.ProductSpecId)
        if(msg){
          return msg
        }else{
          return {
            GiftCount:'',
            ImgUrl:v.ImgUrl,
            ProductName:v.Name,
            Spce:v.Spce,
            Stock:v.Stock,
            Price:v.Price,
            IsOpen:v.IsOpen,
            ProductSpecId:v.ProductSpecId,
          }
        }
      })
      this.selectGiftShow = false
      this.getgiftlist()
    },
    getgiftlist(){
      this.total = this.selectedData.length
      this.form.ProductGiftList = this.selectedData.filter((v,i)=>{
        return i >= (this.currentPage-1)*this.pagesize && i < this.currentPage*this.pagesize
      })
      if((!this.form.ProductGiftList||!this.form.ProductGiftList.length)&&this.currentPage>1){
        this.currentPage--
        this.getgiftlist()
        return
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.getgiftlist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getgiftlist()
    },
    openshopDialog(){
      if(!this.form.StartTime||!this.form.EndTime){
        this.$message.error('请先完善活动时间')
        return 
      }
      this.selectProShow = true
    },
    getSelectPros(shop,isChecked){
      if(!isChecked){
        this.$message.error('活动商品至少选择一件')
        return 
      }else{
        let oldShop = {}
        if(this.form.ProductId==shop.Id){
          oldShop = JSON.parse(JSON.stringify(this.form.shoplist[0]))
        }
        this.form.shoplist = [{
          ActivityPrice:oldShop.ActivityPrice||'',
          ImgUrlComplete:shop.ImgUrlComplete,
          ProductName:shop.Name,
          ProductNo:shop.ProductNo,
          Stock:shop.Stock,
          ProductPrice:shop.ProductPrice,
          ProductMaxPrice:shop.ProductMaxPrice,
          ProductId:shop.Id,
          IsOpen:shop.IsOpen
        }],

        [
          this.form.ProductId,
          this.form.ProductName,
          this.form.ProductNo,
          this.form.ProductPrice,
          this.form.ProductMaxPrice,
          this.form.Stock,
          this.form.ActivityHelpProProductSpecList,
          this.form.ImgUrlComplete
        ] = [
          shop.Id,
          shop.Name,
          shop.ProductNo,
          shop.ProductPrice,
          shop.ProductMaxPrice,
          shop.Stock,
          shop.ProductSpecList.map(v=>{
            v.Id = v.ProductSpecId
            v.Spec = [v.SpecValue,v.SpecValue2].filter(v=>v).join(';')||'默认规格'
            return v
          }),
          shop.ImgUrlComplete,
        ]

        // console.log(this.form.ActivityHelpProProductSpecList,shop.ProductSpecList)
        this.creatOtherlist('CashRewardImmediatelyList')
        this.creatOtherlist('ShareEarnEquityList')
      }
      this.selectProShow = false
    },
    creatOtherlist(name){
      let oldlist = JSON.parse(JSON.stringify(this.form[name]))
      this.form[name] = this.form.ActivityHelpProProductSpecList.map((v,i)=>{
        let msg = {
          ProductSpecId:v.Id,
          Barcode:v.Barcode,
          Price:v.Price,
          Spec:v.Spec
        }
        let lodmsg = oldlist.find(x=>x.ProductSpecId==v.Id)
        if(name=='CashRewardImmediatelyList'){
          msg.RewardMoney = ''
          if(lodmsg){
            msg.RewardMoney = lodmsg.RewardMoney
          }
        }else if(name=='ShareEarnEquityList'){
          msg.ShareEarnMoney = ''
          if(lodmsg){
            msg.ShareEarnMoney = lodmsg.ShareEarnMoney
          }
        }
        return msg
      })
    },
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.form.RecommendRewardType==3){
            for(let item of this.selectedData){
              // console.log(item.GiftCount)
              if(item.GiftCount<1||item.GiftCount>item.Stock){
                this.toError('gifttable')
                return
              }
            }
          }
          this.confirmSave()
        } else {
          this.toError('is-error')
          return false;
        }
      });
    },
    toError(name){
      this.$nextTick(()=>{
        this.$message.error('请完善活动配置')
        let iserror = document.getElementsByClassName(name)
        iserror[0].scrollIntoView({
          block:'center',
          behavior:'smooth'
        })
      })
    },
    async confirmSave(){
      let form = JSON.parse(JSON.stringify(this.form))
      form.ActivityPrice = form.shoplist[0].ActivityPrice
      if(!form.IsLimitInitiateCount){
        form.LimitInitiateCount = ''
      }
      if(!form.IsLimitHelpCount){
        form.LimitHelpCount = ''
      }
      if(!form.IsACanHelpB){
        form.ACanHelpBCount = ''
      }
      if(!form.IsBCanHelpA){
        form.BCanHelpACount = ''
      }
      if(form.RecommendRewardType==1){
        form.CashRewardType = ''
        form.CashRewardMoney = ''
        form.CashRewardImmediatelyList = []
        form.ProductGiftList = []
      }else if(form.RecommendRewardType==2){
        if(form.CashRewardType==1){
          form.CashRewardImmediatelyList = []
        }else{
          form.CashRewardMoney = ''
          form.CashRewardImmediatelyList = form.CashRewardImmediatelyList.map(v=>{
            return {
              ProductSpecId:v.ProductSpecId,
              RewardMoney:v.RewardMoney
            }
          })
        }
        form.ProductGiftList = []
      }else{
        form.CashRewardType = ''
        form.CashRewardMoney = ''
        form.CashRewardImmediatelyList = []
        form.ProductGiftList = this.selectedData.map(v=>{
          return {
            ProductSpecId:v.ProductSpecId,
            GiftCount:v.GiftCount
          }
        })
      }
      if(!form.IsOpenShareEarnEquity){
        form.ShareEarnEquityList = []
      }else{
        form.ShareEarnEquityList = form.ShareEarnEquityList.map(v=>{
          return {
            ProductSpecId:v.ProductSpecId,
            ShareEarnMoney:v.ShareEarnMoney
          }
        })
      }
      form.ActivityHelpProProductSpecList = form.ActivityHelpProProductSpecList.map(v=>{
        return {
          Id:v.Id,
          Spec:v.Spec,
          Price:v.Price
        }
      })
      try{
        this.loading = true
        let res = await activityHelpProactivityHelpProSave(form)
        if(res.IsSuccess){
          this.$message.success('保存成功')
          if(this.form.ActivityFullId){
            this.goback()
            return
          }
          this.showtype = {
            issuccess: true,
            id: res.Result.Id,
            type: 0,
            suctype: 10,
          }
        }
      }finally{
        this.loading = false
      }
    }
  }
}
</script>

<style lang='less' scoped>
.radiocheck{
  ::v-deep .el-radio__inner{
    position: relative;
    &::after{
      box-sizing: content-box;
      content: "";
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
      position: absolute;
      top: 1px;
      left: 4px;
      transform: rotate(45deg) scaleY(0);
      width: 3px;
      height: 7px;
      transition: transform .15s ease-in .05s;
      transform-origin: center;
      background: transparent;
      border-radius: 0px;
    }
  }
  ::v-deep .el-radio__input.is-checked{
    .el-radio__inner:after{
      transform: rotate(45deg) scaleY(1);
    }
  }
  ::v-deep .el-radio__input.is-disabled{
    .el-radio__inner:after{
      background-color: transparent;
      border-color: #C0C4CC;
    }
  }
}
  .bottomCard{
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  .radio-tips{
    font-size: 14px;
    color:#999999FF;
    line-height:1;
    margin-left:25px
  }
  
	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

	}
		.remark {
			color: #E51C23;
			font-size: 12px;
		}
</style>